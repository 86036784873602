<template>
  <master-layout>

    <h1>Meghívom a barátaimat</h1>
    <p>
      Oszd meg barátaiddal a Bortúra Appot és gyűjtsetek közösen minél több élményt!
    </p>

    <ion-card>
      <ion-item lines="none">
        <ion-icon
          :name="linkIcon"
          color="primary"
          slot="end"
          @click="linkCopied()"
        />
        <ion-input
          :value="shareLink"
          @ionChange="linkActive=false"
        ></ion-input>
      </ion-item>
    </ion-card>

    <p>
      Előre megírtuk neked a meghívó szövegét, hogy Te néhány kattintással meg tudd hívni a barátaidat a Bortúra App használatára.
    </p>

    <ion-card>
      <ion-item lines="none">
        <ion-icon
          :name="paragraphIcon"
          color="primary"
          slot="end"
          @click="paragraphCopied()"
        />
        <ion-textarea
          rows="11"
          v-model="shareParagraph"
          @ionChange="paragraphActive=false"
        >
        </ion-textarea>
      </ion-item>
    </ion-card>

    <ion-button class="first-button" expand="block" fill="transparent" color="secondary" @click="$router.go(-1)">Vissza</ion-button>

  </master-layout>
</template>

<script>
import {
  IonButton, IonInput, IonTextarea, IonCard, IonItem, IonIcon
} from '@ionic/vue'
import { addIcons } from 'ionicons'
import {
  copy, copyOutline, checkbox, checkboxOutline
} from 'ionicons/icons'
import { Clipboard } from '@capacitor/clipboard'
import { mapGetters } from 'vuex'

export default {
  name: 'PlatformAjanlas',
  components: {
    IonButton, IonInput, IonTextarea, IonCard, IonItem, IonIcon
  },
  data () {
    return {
      linkActive: false,
      paragraphActive: false,
      shareText: "Szia! Letöltöttem a Bortúra Appot, szerintem nagyon szuper. Arra gondoltam, hogy gyűjtsük közösen a borélményeket, nézd meg, hogy hova menjünk legközelebb.\n\nEzen a linken keresztül tudod a leggyorsabban letölteni:\n\n",
      shareParagraph: ""
    }
  },
  computed: {
    ...mapGetters('auth', {
      authData: 'getAuthData'
    }),
    shareLink () {
      let link = 'https://app.orszagosbortura.hu/invite?'
      if (this.authData && this.authData.userId) {
        link += this.authData.userId
      }
      return link
    },
    linkIcon () {
      if (this.linkActive) {
        return 'checkbox-outline'
      } else {
        return 'copy-outline'
      }
    },
    paragraphIcon () {
      if (this.paragraphActive) {
        return 'checkbox-outline'
      } else {
        return 'copy-outline'
      }
    }
  },
  ionViewWillEnter () {
    this.linkActive = false
    this.paragraphActive = false
    this.shareParagraph = this.shareText + this.shareLink
  },
  created () {
    addIcons({
      'copy-outline': copyOutline,
      'copy': copy,
      'checkbox': checkbox,
      'checkbox-outline': checkboxOutline,
    })
  },
  methods: {
    async linkCopied () {
      await Clipboard.write({
        string: this.shareLink
      })
      this.linkActive = true
      this.paragraphActive = false
    },
    async paragraphCopied () {
      await Clipboard.write({
        string: this.shareParagraph
      })
      this.linkActive = false
      this.paragraphActive = true
    }
  }
}
</script>

<style scoped>

ion-button{
  margin: 5px 40px;
}
.first-button{
  margin-top: 25px;
  margin-bottom: 10px;
}
ion-card{
  margin: 0 20px;
}
p{
  margin: 25px 20px 20px 20px;
  line-height: 1.5;
}
</style>
